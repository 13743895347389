import React from "react"
import ResponsiveLayout from "../components/ResponsiveLayout"
import PageTitle from "../components/ResponsiveLayout/pageTitle"
import knappExempelImg from "../Images/medialinq-knapp-exempel.png"

const Faqs = (props) => {

  let menuPages = [
          { title: "Sidor",
            data:
            [
              { title: "Startsida", data: {href: "/index.html"} },
              { title: "Donator", data: {href: "/donator/index.html"} },
              { title: "Nudgewall", data: {href: "/nudgewall/index.html"} },
              { title: "Nätskribent", data: {href: "/mottagare/index.html"} },
              { title: "Vanliga frågor", data: {href: "/faqs/index.html"} }
            ]
          }
      ]

  return (
      <ResponsiveLayout {...props} pages={menuPages}>
        <PageTitle {...props} pagename={{ short: "Nätskribent", long: "Information för nätskribenter och dig som publicerar på webben"
        }}  />
        <div className="container">
          <div className="section-header">
            <h2>Utmaningen</h2>
            <p>Bedömningen inom fria media är att endast ca 1 % av besökarna/läsarna donerar. Vi har därför utvecklat
              ett mikrodonationssystem MediaLinq som tillsammans med en ny metod arbetar för att väcka de resterande 99%
              gratisläsarna. Deras dåliga samvete ska lindras på ett nytt sätt. </p>

              <ul>
                <li>
                  <h4>1. 5 sekunder för en donation till dig</h4>
                  <p>
                  En donator registrerar sig hos valfri publicist inom fria media. Man betalar därvid in en klumpsumma
                    till ett eget donationskonto. Man blir sedan igenkänd hos alla publicister som använder MediaLinq.
                    Donatorn ser direkt saldot på sitt donationskonto. Man klickar på önskat donationsbelopp och bekräftar.
                    Total tid ca 5 sek. Gamla ursäkter om att det är omständigt och tar lång tid kan äntligen elimineras.
                  </p>
                    <div style={{marginTop: "1em"}} className="donera-container-medium donera-bordered">
                      <iframe title="Donera"
                              className="donera_button"
                              data-header="Visa uppskattning under 5 sekunder"
                              data-extra="Klicka här för ditt stöd"
                              data-recipient="f26faaa8-9208-11ea-bb37-0242ac130002"
                              data-amounts="1,5,10"
                              scrolling="no"></iframe>
                    </div>

                </li>
                <li>
                  <h4>2. Även små belopp kan bli lönsamma för dig</h4>
                  <p>
                    Överföringar av donationer till resp publicister/mottagare görs utan bankavgift. Därmed kan tex en
                    donation på 1 kr ge dig 94 öre. Skulle varje liten donation inte samlas upp utan överföras varje gång
                    skulle publicister med firma betala bankavgift på mellan 1.5 kr och 3 kr per donation.

                    Erfarenheten visar att en läsare som väntar tills det är motiverat med ett större belopp oftast inte
                    genomför den större betalningen. Mikrobetalningar är framtiden för spontana betalningar.
                  </p>
                  <p style={{marginTop: "1em"}}>
                    <a href={"https://www.medialinq.se/donera/index.html?r=f26faaa8-9208-11ea-bb37-0242ac130002&s=referrer&a=1"}>
                      <img src={knappExempelImg} className="img-fluid" alt="Img" style={{maxWidth: "360px"}}/>
                    </a>
                  </p>
                </li>

                <li>
                  <h4>3. Tydliga budskap på donationsknappen</h4>
                  <p>
                    Erfarenheten visar att ordet Donera som enda text är för svagt för att locka och kan uppfattas som
                    en ett välgörenhetsbidrag.  Det behövs ett budskap som läsaren kan solidarisera sig med. Du kan
                    därför skapa dina egna texter. Variera gärna dem ofta på olika artiklar/sidor där det är möjligt.
                  </p>
                    <div style={{marginTop: "1em"}} className="donera-container-large donera-bordered">
                      <iframe title="Donera"
                              className="donera_button"
                              data-header="Vill du se fler grävande reportage"
                              data-extra="Donera till Min Tidning"
                              data-extra2="Klicka här för ditt stöd"
                              data-recipient="f26faaa8-9208-11ea-bb37-0242ac130002"
                              data-amounts="1,5,10"
                              scrolling="no"></iframe>
                    </div>
                </li>

                <li>
                  <h4>4. Placering där den märks dvs även i artikeltexten</h4>
                  <p>
                    Annonser placeras ofta där läsarintresset är på topp dvs i eller nära artikeltexterna. Donationsknappen
                    bör ses som en annons för din egna verksamhet. Eftersom donationer ofta ligger som en separat flik
                    eller längst ned när texten är läst så blir den oftast inte uppmärksammad, 99 % vill dessutom slippa
                    betala så länge deras samvete inte väcks på ett bättre sätt. Var inte blyg. Dina texter har redan
                    lockat läsarna till dig. Du är uppskattad men blir för lite belönad.
                  </p>
                </li>

                <li>
                  <h4>5. Gemensamt för fria media och därmed igenkänning hos alla anslutna</h4>
                  <p>
                    Varje donator/mediekonsument inom fria media som registrerar sig i MediaLinq systemet blir igenkänd
                    oavsett var en donationsknapp finns placerad. Därför finns det skäl att se andra publicister
                    inom fria media som kollegor då varje ny donator ökar möjligheten till att det är du som får
                    nästa donation från en tidigare gratisläsare. Trots allt, det är ett gemensamt problem att i snitt
                    99 % av fria media konsumenterna inte donerar.
                  </p>
                </li>
              </ul>
            <p style={{marginTop: "2em"}}></p>
                <h2>Vi är ingen vanlig betalförmedlare. Vi hjälper dig till ökade intäkter</h2>
                <p>
                  MediaLinq är ett enkelt, smart och snabbt betalsystem för mikrobetalningar. Alla betalningar sammanställs
                  månadsvis i ett dokument som blir ditt underlag för vår utbetalning och din redovisning. Mer praktiskt
                  kan det inte bli. Vi kan också tillhandahålla motiverande texter och förklaringar som kan hjälpa dig
                  när du introducerar MediaLinq.
                </p>

          </div>
        </div>
      </ResponsiveLayout>
  )
}

export default Faqs
